import matchtitle from '../../components/matchtitle';
import {

	Message
} from 'element-ui'

import {
	handlePageQueryGroupsettingy,
	// handleDeleteGroupsetting,
	handleSave,
	handleAgeControll,
	handleDownload,
	handleSetGroupsetttingSignupStatus,
	handleaAddGroup
} from '@index/api/mymatchs/groupsetitem';
export default {
	name: 'onlinesignuptable',
	components: {
		matchtitle
	},
	data() {
		return {
			fullscreenLoading: false,
			matchRules: ['等级赛', '名次赛', '奖金赛', '赛制不限', '淘汰赛', '精英赛', '竞技赛', '进阶赛'],
			genderRequirements: ["不限", "男", "女", "男女", "女女"],
			types: ["双人组", "多人组", "单人组", "不限"],
			havAuth: false,
			headers: {
				'Authorization': sessionStorage.getItem('token')
			},
			path: process.env.VUE_APP_PROXY_URL + "groupsetting/upload",
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			queryForm: {},
			tableData: [],
			matchId: sessionStorage.getItem("currMatchId"),
			uploadFileParams: {
				matchId: sessionStorage.getItem("currMatchId")
			},
			highlightCurrentRow: false,
			showEdit: true,
			setSignupStatusDialogVisible: false,
			setSignupStatusForm: { id: '', signupStatus: '' },
			addGroupDialogVisible: false,
			addGroupForm: {},
		};
	},
	watch: {

	},
	created() {
		this.pageQuery();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		initAuth(havAuth) {
			this.havAuth = havAuth;
			this.havAuth = true;
		},
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageQueryGroupsettingy({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con: this.queryForm.con,
				matchId: this.matchId,
				sortWay: [{
					fieldName: 'orderNo',
					sortWay: 'ASC'
				}, {
					fieldName: 'groupNo',
					sortWay: 'ASC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		uploadSuccess(res) { //上传成功	
			this.$refs.upload.clearFiles();
			this.fullscreenLoading = false;
			if (res.status != 200) {
				Message({
					message: '上传失败!',
					type: 'error'
				});
				return;
			}
			Message({
				message: '上传成功!',
				type: 'success',
				duration: 5 * 1000
			});
			this.currPage = 1;
			this.queryForm.con = "";
			this.fastQuery();

		},
		beforeUpload() {
			this.fullscreenLoading = true;
		},
		uploadError() {
			this.fullscreenLoading = false;
		},
		rowEdit(id) {
			if (this.tableData && this.tableData.length > 0) {

				this.tableData.forEach((elem) => {
					if (elem.id == id) {
						elem.editFlag = '1';
					} else {
						elem.editFlag = '0';
					}
				});
			}

			// this.highlightCurrentRow = true;

		},
		rowSave(data) {
			handleSave(data).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					});
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					});
				}
			});
			this.highlightCurrentRow = false;
		},
		rowCancel() {
			this.highlightCurrentRow = false;
			if (this.tableData && this.tableData.length > 0) {
				this.tableData.forEach((elem) => {
					elem.editFlag = false;
				});
			}
		},
		ageControll(id, ageRequirements) {
			var tips = "";
			if (id) {
				tips = ageRequirements == '0' ? '请确认是否停用当前年龄限制?' : '请确认是否启用当前年龄限制?';
			} else {
				tips = ageRequirements == '0' ? '请确认是否全部停用当前年龄限制?' : '请确认是否全部启用当前年龄限制?';
			}

			this.$confirm(tips, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				confirmButtonClass: 'confirmButtonClass',
				type: 'warning '
			}).then(() => {
				handleAgeControll({
					id: id,
					ageRequirements: ageRequirements,
					matchId: this.matchId
				}).then(res => {
					if (res.status == 200) {
						Message({
							message: res.msg,
							type: 'success',
							duration: 5 * 1000
						});
						this.pageQuery();
					} else {
						Message({
							message: res.msg,
							type: 'error',
							duration: 5 * 1000
						});
					}
				});
			});
		},
		download() {
			handleDownload(this.matchId).then(res => {
				var fileName = "组别设项.xls";
				var blob = new Blob([res], {
					type: "application/octet-stream"
				});
				if (window.navigator.msSaveOrOpenBlob) { //msSaveOrOpenBlob方法返回bool值

					navigator.msSaveBlob(blob, fileName); //本地保存
				} else {

					var link = document.createElement('a'); //a标签下载
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName;
					link.click();
					window.URL.revokeObjectURL(link.href);
				}
			});

		}
		, openSetSignupStatus(id, signupStatus) {
			console.log(">>>>>>>>>" + id + "    " + signupStatus);
			if (!signupStatus) {
				signupStatus = '0';
			}
			this.setSignupStatusDialogVisible = true;
			this.setSignupStatusForm = { id: id, signupStatus: signupStatus };
		}
		, openAddGroupDialogEvent() {
			this.addGroupForm = {};
			this.addGroupDialogVisible = true;
		},
		submitSignupStatusForm() {
			this.requestSetGroupsetttingSignupStatus(this.setSignupStatusForm.id, "", this.setSignupStatusForm.signupStatus);
		},
		setSignupStatusEvent(signupStatus) {
			this.requestSetGroupsetttingSignupStatus('', this.matchId, signupStatus);
		},
		requestSetGroupsetttingSignupStatus(id, matchId, signupStatus) {
			handleSetGroupsetttingSignupStatus({
				id: id,
				matchId: matchId,
				signupStatus: signupStatus,
			}).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					});
					this.setSignupStatusDialogVisible = false;
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					});
				}
			})
		},
		addGroupEvent() {
			this.addGroupForm.matchId = this.matchId;
			handleaAddGroup(this.addGroupForm).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					});
					this.addGroupDialogVisible = false;
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					});
				}
			})
		},


	}
}